import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import HeaderLight from "../../../common/header/HeaderLight";
import FooterOne from "../../../common/footer/FooterOne";

import banner from "../../../../src/app/assets/images/casestudy/jugl/banner.png";
import mini from "../../../../src/app/assets/images/casestudy/tech/mini-equ.png";
import cutout1 from "../../../../src/app/assets/images/casestudy/jugl/cutout1.png";
import cutout2 from "../../../../src/app/assets/images/casestudy/jugl/cutout2.png";
import cutout3 from "../../../../src/app/assets/images/casestudy/jugl/cutout3.png";

import tech1 from "../../../../src/app/assets/images/casestudy/tech/html.png";
import tech2 from "../../../../src/app/assets/images/casestudy/tech/css.png";
import tech3 from "../../../../src/app/assets/images/casestudy/tech/aws.png";
import tech4 from "../../../../src/app/assets/images/casestudy/tech/appium.png";
import tech5 from "../../../../src/app/assets/images/casestudy/tech/react.png";
import tech6 from "../../../../src/app/assets/images/casestudy/tech/elixir.png";
import tech7 from "../../../../src/app/assets/images/casestudy/tech/erlang.png";
import tech8 from "../../../../src/app/assets/images/casestudy/tech/rest.png";
import tech9 from "../../../../src/app/assets/images/casestudy/tech/docker.png";
import tech10 from "../../../../src/app/assets/images/casestudy/tech/p-sql.png";
import tech11 from "../../../../src/app/assets/images/casestudy/tech/mobx.png";
import tech12 from "../../../../src/app/assets/images/casestudy/tech/js.png";

import case1 from "../../../../src/app/assets/images/casestudy/othercase/attesta.png";
import case2 from "../../../../src/app/assets/images/casestudy/othercase/bussopi.png";
import case3 from "../../../../src/app/assets/images/casestudy/othercase/genie.png";

const Jugl = () => {
  useEffect(() => {
    document.title = "Jugl | CAP Digisoft ";
  }, []);

  return (
    <div className="casestudy-attesta-page">
      <HeaderLight />
      <div className="container-fluid pt-0 px-0 margin-minus">
        <div className="masthead tracky pt-lg-5">
          <h2 class="text-white text-center pt-5">JUGL</h2>
          <p class="text-white text-center pb-4 pt-3 px-4">
            An all-in-one solution for order management and resource planning.
          </p>
          <div className="row justify-content-center">
            <div className="img text-center">
              <img alt="" class="img-fluid rounded-3 pb-5" src={banner} />
            </div>
          </div>
        </div>
      </div>
      <div className="about py-5">
        <div className="container custom-cn-width">
          <div className="row align-items-center">
            <div className="col-xl-3 col-md-4 col-sm-12 ">
              <div className="heading">
                <div className="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">What is JUGL?</p>
                </div>
              </div>
            </div>
            <div className="col-xl-9 col-md-8 col-sm-12">
              <p className="p-4 p-lg-0">
                Jugl is a mobile app designed to simplify order management by
                allowing users to receive, assign, and track orders in
                real-time. It includes resource planning features, enabling
                businesses to manage employees, track productivity, and share
                progress updates with clients.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="picture">
        <div className="container custom-cn-width">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="picture-img text-center">
                <img class="tracky-mockup img-fluid" src={cutout1} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="position-relative py-5">
        <div className="container custom-cn-width">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="heading">
                <div className="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Scenario</p>
                </div>
              </div>
              <p class="p-4 pt-2">
                Businesses often struggle to streamline order processing, manage
                tasks, and keep clients updated, leading to delays and reduced
                productivity.
              </p>
              <div className="heading">
                <div className="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">CDS Solution Approach</p>
                </div>
              </div>
              <p class="p-4 pt-2">
                Jugl centralizes order management tasks with features like
                real-time tracking, order calendars, and client updates, while
                also optimizing resource management and communication through
                chat, location logs, and reporting tools.
              </p>
            </div>
            <div className="col-lg-6">
              <div className="tracky-middle  d-flex justify-content-center">
                <img class="rounded-3 py-0 img-fluid" src={cutout2} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="picture1">
        <div className="container custom-cn-width">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="picture1-img text-center">
                <img class="rounded-3 py-0 img-fluid" src={cutout3} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="impact py-5">
        <div className="container custom-cn-width">
          <div className="row">
            <div className="col-lg-3 col-sm-12 ">
              <div className="heading">
                <div className="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Impact</p>
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-sm-12  ">
              <ul class="mt-top list-styled">
                <li>Boosted productivity by 25-30%</li>
                <li>Real-time client updates for transparency</li>
                <li>Efficient task and employee management</li>
                <li>Customizable reports for actionable insights</li>
                <li>Simplified order assignment and tracking</li>
                <li>Better workflow prioritization, minimizing delays</li>
                <li>Centralized platform for order reception and monitoring</li>
              </ul>
            </div>
            <div className="col-lg-3 col-sm-12 pb-3 pt-5">
              <div className="heading">
                <div className="content1 d-flex align-items-start ">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Technology Stack</p>
                </div>
              </div>
            </div>

            <div className="col-lg-9 col-sm-12 pt-lg-5">
              <div className="impact-img4 d-flex flex-wrap align-items-center">
                <div className="img2 p-2 pb-0 case">
                  <img src={tech1} class="img-fluid" alt="" />
                </div>
                <div className="img2 p-2 pb-0 case">
                  <img src={tech2} class="img-fluid" alt="" />
                </div>
                <div className="img2 p-2 pb-0  case">
                  <img src={tech3} class="img-fluid" alt="" />
                </div>
                <div className="img2 p-2 pb-0 case">
                  <img src={tech4} class="img-fluid" alt="" />
                </div>
                <div className="img2 p-2 pb-0 case">
                  <img src={tech5} class="img-fluid" alt="" />
                </div>
                <div className="img2 p-2 pb-0 case">
                  <img src={tech6} class="img-fluid" alt="" />
                </div>
                <div className="img2 p-2 pb-0 case">
                  <img src={tech7} class="img-fluid" alt="" />
                </div>
                <div className="img2 p-2 pb-0 case">
                  <img src={tech8} class="img-fluid" alt="" />
                </div>
                <div className="img2 p-2 pb-0  case">
                  <img src={tech9} class="img-fluid" alt="" />
                </div>
                <div className="img2 p-2 pb-0 case">
                  <img src={tech10} class="img-fluid" alt="" />
                </div>
                <div className="img2 p-2 pb-0 case">
                  <img src={tech11} class="img-fluid" alt="" />
                </div>
                <div className="img2 p-2 pb-0 case">
                  <img src={tech12} class="img-fluid" alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-12  pt-5">
              <div className="heading">
                <div className="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Conclusion</p>
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-sm-12 pt-lg-5 pt-2">
              <p className="pt-1 pt-lg-4 p-4 p-lg-0">
                Jugl transforms business operations by providing a comprehensive
                order management and resource planning platform, designed to
                enhance productivity and meet customer expectations.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="more-cases pb-5">
        <div className="container custom-cn-width">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="heading">
                <div className="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Other Case Studies</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row text-center py-4 g-4">
            <div className="col-lg-4 col-sm-12">
              <Link to="/attesta_ai">
                <div className="case rounded-3  h-100 case1 p-4 bg-light">
                  <div className="case-title">
                    <h4 class="">Attesta AI</h4>
                  </div>
                  <p class=" pt-2">
                    {" "}
                    Amazing AI-based document discovery to Reduce your review
                    time by 70%{" "}
                  </p>
                  <div className="case-img">
                    <img class="img-fluid rounded py-3" src={case1} alt="" />
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-sm-12 ">
              <Link to="/bussopi">
                <div className="case rounded-3 h-100 case1 p-4 bg-light">
                  <div className="case-title">
                    <h4 class="t">Bussopi</h4>
                  </div>
                  <p class=" pt-2">School app for learning</p>
                  <div className="case-img">
                    <img class="img-fluid rounded py-3" src={case2} alt="" />
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-sm-12">
              <Link to="/genie">
                <div className="case rounded-3 h-100 case1 p-4 bg-light">
                  <div className="case-title">
                    <h4 class="">Genie</h4>
                  </div>
                  <p class=" pt-2">
                    The Genie app serves as a transformative launcher
                  </p>
                  <div className="case-img">
                    <img class="img-fluid rounded py-3" src={case3} alt="" />
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <FooterOne />
    </div>
  );
};

export default Jugl;
